.vector-icon8,
.vector-icon9 {
  position: absolute;
  height: 63.62%;
  width: 25.86%;
  top: 36.38%;
  right: 0;
  bottom: 0;
  left: 74.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon9 {
  height: 58.36%;
  width: 51.98%;
  top: 41.64%;
  right: 48.02%;
  left: 0;
}
.icon6 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.frame-child9 {
  position: absolute;
  top: 19px;
  left: 10%;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 1180px;
  height: 683px;
}
.experiential-and-outdoor {
  position: absolute;
  top: 70px;
  left: 152px;
  display: inline-block;
  width: 556px;
}
.blank-line7 {
  margin: 0;
}
.team-building-excellence-cust {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.inspiring-retreats-tailored {
  margin-bottom: 0;
}
.create-memorable-and-container,
.team-building-excellence-cust-container,
.teams-organisations-and-container {
  position: absolute;
  top: 300px;
  left: 89px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 482px;
}
.create-memorable-and-container,
.teams-organisations-and-container {
  top: 292px;
  left: 659px;
  width: 474px;
  height: 65px;
  z-index: 5;
}
.teams-organisations-and-container {
  top: 525px;
  height: 68px;
}
.benefits8 {
  top: 228px;
  left: 705px;
  width: 110px;
  height: 24px;
}
.benefits8,
.ideal-for4,
.offerings4 {
  position: absolute;
  display: inline-block;
}
.ideal-for4 {
  top: 461px;
  left: 721px;
  width: 110px;
  height: 24px;
}
.offerings4 {
  top: 235px;
  left: 89px;
  width: 130px;
}
.benefit-icon4 {
  position: absolute;
  top: 223.5px;
  left: 657.5px;
  width: 33px;
  height: 33px;
}
.frame-child10,
.frame-child11 {
  position: absolute;
  top: 201px;
  left: 50%;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 537px;
  height: 198px;
}
.frame-child11 {
  top: 426px;
}
.ideal-icon4 {
  position: absolute;
  top: 450px;
  left: 659px;
  width: 46px;
  height: 46px;
}
.outdoor-services-icon1 {
  position: absolute;
  top: 49.5px;
  left: 40.5px;
  width: 79px;
  height: 75px;
}
.rectangle-parent1 {
  position: relative;
   top: 5%;
  left:10%; 
  /* width: 1200px;
  height: 704px; */
  text-align: left;
  font-size: var(--heading-2-size);
  justify-content: center;
  align-items: center;
  display:FLEX;

}
.frame-icon7 {
  position: relative;
  width: 20px;
  height: 20px;
}
.auto-layout-horizontal9,
.auto-layout-horizontal10 ,
.auto-layout-vertical3 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
}
.auto-layout-horizontal9 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.auto-layout-horizontal10,
.auto-layout-vertical3 {
  position: absolute;
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.auto-layout-vertical3 {
  top: calc(50% - 276px);
  right: 133px;
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal9 {
  top: 140px;
  left: 1171px;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.button5 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary5 {
  position: absolute;
  top: 85%;
  left: 72%;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.glossary4 {
  left: 1050px;
  width: 74px;
}
.about4,
.contact4,
.glossary4,
.home4,
.rent-a-skill4,
.services4 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.about4 {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.contact4,
.home4,
.rent-a-skill4,
.services4 {
  left: 606px;
  width: 53px;
}
.contact4,
.rent-a-skill4,
.services4 {
  left: 904px;
  width: 106px;
}
.contact4,
.services4 {
  left: 790px;
  width: 74px;
  cursor: pointer;
}
.contact4 {
  left: 1164px;
  width: 66px;
}
.our-services-4-child {
  position: absolute;
  top: 61.5px;
  left: 72.8%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
}
.our-services-4 {
  position: relative;
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: 100% auto;
  width: 100%;
  height: 115vh;
  /* overflow: hidden; */
  object-fit: contain !important;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit4{
  margin-right:78%;
 margin-top:7%;
}      

@media (max-width: 768px){
  .our-services-4 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: cover;
  overflow: hidden;
height: 800px;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit4{
  margin-right:78%;
   z-index:1;
  margin-top: 15%; 
}
.Save4{
  margin-right:40%;
  margin-top:15%;
   z-index:1;
}
.rectangle-parent2 {
  position: absolute;
  top: 64px;
  left: 33px;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
  z-index: 1;
}
.frame-child12 {
  position: absolute;
  top: 19px;
  left:-15px;
 
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 300px;
  height: 660px;

} 
.outdoor-services-icon1 {
  top: 51px;
  left: 1px;
  width: 66px;
  height: 40px;
}
.experiential-and-outdoor {
  position: absolute;
  top: 60px;
  left:70px;
  display: inline-block;
  width: 556px;
  font-size: 14px;
}
.offerings4 {
  top: 120px;
  left: 0px;
  width: 130px;
}
  
.team-building-excellence-cust {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.inspiring-retreats-tailored {
  margin-bottom: 0;
}

.team-building-excellence-cust-container  
 {
  position: absolute;
  top: 150px;
  left: 1px;
  font-size: 12px;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 280px;
}
.frame-child10,
.frame-child11 {
  position: absolute;
  top: 310px;
  left: 1px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 250px;
  height: 140px;
}
.frame-child11{
  top: 470px;
}
.enhance-organizational-and-container,
.organizations-and-individuals-container {
  position: absolute;
  top: 350px;
  left: 2px;
  font-size: 14px;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 247px;
  height: 65px;
  z-index: 5;
}
.benefits8,
.ideal-for4,
.offerings4 {
  position: absolute;
  display: inline-block;
} 
.benefits8 {
  top: 335px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.benefit-icon4 {
  position: absolute;
  top: 330px;
  left: 2px;
  width: 20px;
  height: 25px;
}
.ideal-for4 {
  top: 480px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.ideal-icon4 {
  position: absolute;
  top: 475px;
  left: 2px;
  width: 30px;
  height: 30px;
}
.organizations-and-individuals-container {
  top: 510px;
  height: 68px;
}
.statesecondary5 {
  position: absolute;
  top: 690px;
  left: 95px;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.auto-layout-horizontal10,
.auto-layout-horizontal9,
.auto-layout-vertical4 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-layout-horizontal9 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.auto-layout-horizontal10,
.auto-layout-vertical4 {
  position: absolute;
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.auto-layout-vertical4 {
  top: 16.80%;
  left:75%;
 
   
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal10 {
  top: 95px;
  left:76%;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
  width: 25px;
  height: 25px; 
}
.frame-child10,
.frame-child11 {
  position: absolute;
  top: 320px;
  left: 1px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 250px;
  height: 140px;
}
.frame-child11 {
  top: 470px;
}
.create-memorable-and-container {
  position: absolute;
  top: 370px;
  left: 1px;
  font-size:12px;
  font-family: var(--font-inherit);
  display: inline-block;
  width:247px;
}
.teams-organisations-and-container {
  top: 300px;
  left: 1px;
  width: 250px;
  height: 65px;
  z-index: 5;
  font-size:12px;
}
.teams-organisations-and-container {
  top: 525px;
  height: 68px;
}
.our-services-4-child {
  display: none;
}
}