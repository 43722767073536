/* .vector-icon19,
.vector-icon20 {
  position: absolute;
  height: 69.68%;
  width: 20.21%;
  top: 20.72%;
  right: 0;
  bottom: 9.6%;
  left: 79.79%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon20 {
  height: 26.55%;
  width: 53.75%;
  top: 73.45%;
  right: 46.25%;
  bottom: 0;
  left: 0; 
 } */
.button12 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary11,
.statesecondary12,
.statesecondary13 {
  position: absolute;
  top: 32%;
  border-radius: var(--br-981xl);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkkhaki);
}
.statesecondary11 {
  left: 30%;
  cursor: pointer;
}
.statesecondary12,
.statesecondary13 {
  left: 42%;
}
.statesecondary13 {
  left: 62%;
  cursor: pointer;
}
.icon12 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.about9,
.contact9,
.glossary9,
.home9,
.rent-a-skill9,
.services9 {
  position: absolute;
  top: 36px;
  left: 1090px;
  display: inline-block;
  width: 119px;
  height: 26px;
}
.about9,
.contact9,
.glossary9,
.home9,
.services9 {
  left: 1236px;
  width: 74px;
}
.about9,
.contact9,
.home9,
.services9 {
  left: 885px;
  width: 51px;
}
.contact9,
.home9,
.services9 {
  left: 792px;
  width: 53px;
  cursor: pointer;
}
.contact9,
.services9 {
  left: 976px;
  width: 74px;
}
.contact9 {
  left: 1350px;
  width: 66px;
}
.about-page-11-child {
  position: absolute;
  top: 61.5px;
  left: 66.7%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
}
.who-we-are {
  position: relative;
  top: 10px;
  /* left: 442px; */
  font-size: var(--heading-1-size);
  display: inline-block;
  width: 315px;
  height: 62px;
}
.and-the-bottom {
  margin: 0;
}
.and-the-bottom-container {
  position: absolute;
  top: 160px;
  /* left: 24px; */
  text-align: center;
  display: inline-block;
  width: 1156px;
  height: 418px;
}
.the-only-constant {
  position: absolute;
  top: 90px;
  /* left: 243px; */
  font-size: var(--subheading-size);
  font-weight: 300;
  display: inline-block;
  width: 721px;
}
.the-only-constant-textfield{
  position: absolute;
  top: 227%;
  /* left: 243px; */
  font-size: var(--subheading-size);
  font-weight: 200;
  display: flex;
  width: 600px;
  height: 12px;
}
.the-only-constant-textfield1{
 
  left:25% ;
  /* left: 243px; */

  
  width: 600px;
  height: 12px;
}  
.the-only-constant-textfield2{
  /* position: absolute;
  left:25% ; */
  /* left: 243px; */
  /* font-size: var(--subheading-size);
  font-weight: 200;
  display: flex;*/
  width: 600px;
  height: 12px;
}  
.the-only-constant-textfield3{
  /* position: absolute;
  left:25% ; 
  left: 243px;
  font-size: var(--subheading-size);
  font-weight: 200;
  display: flex; */
  width: 600px;
  height: 12px;
}   
 
.the-only-constant-textfieldvision
{
  width: 60px;
  height: 12px;
}
.read-less {
  position: absolute;
padding-top: 120%;
  left: 85%; 
  color: var(--color-darkkhaki);
  /* align-items: left; */
  cursor: pointer;
}
.who-we-are-parent {
  position: relative;
  justify-content: center;
  display: flex;
  top: 112px;
   text-align: center;
  /* left: 135px; */
  width: 100%;
  /* height: 542px */
}
/* .vector-icon21 {
  position: absolute;
  height: 11.78%;
  width: 87.8%;
  top: 0;
  right: 10.34%;
  bottom: 88.22%;
  left: 1.86%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
} */
/* THIS IS MISSION AND VISION BOX */
.about-page-11-inner,
.about-page-11-item {
  position: absolute;
  top: 40%;
  left: 15%;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-700);
  border: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 1196px;
  height: 180px;
}

.about-page-11-inner {
  top:50%;
  height: 243px;
}
.our-vision-mission {
   top: 822px;
   left: 30%;  
  justify-content: center;
  display: flex;
  font-size: var(--heading-1-size);
  /* text-align: left;
  width: 711px; */
  text-align: center;
}
.embark-on-your-container,
.our-vision-mission,
.to-be-a {
  position: absolute;
  display: inline-block;
  
}
.to-be-a {
   top: 42%;
   left: 35%;
  text-align: right;
  width: 740px;
 
}
.embark-on-your-container {
  top: 2180px;
  left: 500px;
  width: 588px;
  height: 64px;
  text-align: center;
}
.statesecondary14,
.statesecondary15,
.statesecondary16 {
  position: absolute;
  top: 2273px;
  border-radius: var(--br-981xl);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkkhaki);
}
.statesecondary14 {
  left: 36%;
  cursor: pointer;
}
.statesecondary15,
.statesecondary16 {
  left: 62%;
}
.statesecondary16 {
 left: 48%;
}
.mission,
.vision {
  position: absolute;
  top: 43%;
  left: 20%;
  font-size: var(--black-size);
  font-weight: 900;
}
.mission {
  position: absolute;
  top: 54%;
  left: 80%;
}
.to-champion-transformative {
  position: absolute;
  top: 52%;
  left: 20%;
  text-align: left;
  display: inline-block;
  width: 930px;
}
.values {
  position: absolute;
  top: 62%;
  left: 50%;
  font-size: var(--black-size);
  font-weight: 900;
}
.our-values-are {
  position: absolute;
  top: 64%;
  left: 30%;
  display: inline-block;
  width: 48%;
}
.group-item {
  /* position: relative; */
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-700);
  border: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 326px;
  height: 222px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.innovative-empowerment {
font-weight: bold;
}
.we-foster-an {
  margin: 0;
  font-size: var(--body-text-size);
}
.innovative-empowerment-we-container {
  /* position: absolute;
  top: 39px;
  /* left: 40px; */
  display: inline-block;
  /* width: 247px;  */
  left: 240px
  
}
.rectangle-parent5 {
  /* position: absolute;
  top: 68%;
  left: 16%; */

 
  margin: 40px;

  width: 326px;
  height: 222px;
  font-size: var(--button-text-size);
  
 
} @media (max-width: 768px) {
  .rectangle-parent5  {
   /* display: none; */
   /* background-color: red; */
   margin: 20px; 
  

  }
}
.inclusive-understanding-we-container {
  /* position: absolute; */
  /* top: 39px;
  left: 21px;
  display: inline-block;
  width: 285px; */
}
.rectangletest{
  position: absolute;
  top: 68%;
  display: flex;
  justify-content: space-around; 
   /* align-items: space-between; */
   width: 100%;
   height: 500px;
  flex-wrap: wrap;
  /* background-color: aliceblue; */
}
.rectangle-parent6 {
  /* position: absolute; */
  /* top: 68%; */
  /* left: 43%; */
  width: 326px;
  height: 222px;
  font-size: var(--button-text-size);
  margin: 40px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.transparent-integrity {
  margin: 0;
  font-size: var(--button-text-size);
  text-transform: uppercase;
  font-weight: 900;
}
.transparent-integrity-we-container {
  position: absolute;
  top: 49px;
  left: 23px;
  display: inline-block;
  width: 280px;
}
.rectangle-parent7 {
   position: absolute;
  top: 59%;
  left: 50%; 
  width: 326px;
  height: 222px;
  display:flex;
  margin: 40px;

} 
.reflective-responsibility-we-container {
  position: absolute;
  top: 17px;
  left: 10px;
  display: inline-block;
  width: 307px;
}
.rectangle-parent8 {
   position: absolute;
  top: 59%;
  left: 24%; 
  width: 326px;
  height: 222px;
  margin: 40px;

}
.active-listening-we-container {
  /* position: absolute;
  top: 17px;
  left: 12px;
  display: inline-block;
  width: 303px; */
}
.rectangle-parent9 {
  /* position: absolute;
  top:59%;
  left: 30%; */
  width: 326px;
  height: 222px;
  margin: 40px;

}
.about-page-11 {
  position: relative;
  background-image: url("../images/Aboutpage-3.png"); 
 
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  height: 100vh;
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  width: 100%;
  height: 2329px;
  overflow: hidden;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
  /* object-fit: contain !important; */
}
.editbuttonmiddle{
  position:absolute;
  top: 38%;
  cursor: pointer;
  height: 30px;
left: 48%;
}
.editbuttonvalue{
  position:absolute;
  top: 80%;
  left: 46%;
  cursor: pointer;
  height: 30px;
  float: right;
}
.mainedit{
  position:absolute;
  top: 70px;
  cursor: pointer;
  height: 30px;
  float: right;
}
.mainsave{
  position:absolute;
  top: 180px;
  left: 750px;
  cursor: pointer;
  height: 30px;
  float: right;
}

@media (max-width: 768px){

  .about-page-11 {
    position: relative;
    background-image: url("../images/Aboutpage-3.png");
   
    background-size: cover;
    /* background-position: center; */
    background-repeat: no-repeat;
    /* height: 250vh; */
    /* background: linear-gradient(59.63deg, #282828, #353535); */
    width: 100%;
    height: 3000px;
    overflow: hidden;
    text-align: center;
    font-size: var(--body-text-size);
    color: var(--color-whitesmoke);
    font-family: var(--body-text);
    object-fit: contain !important;
  }
  .who-we-are {
    position: relative;
    top: 1px;
    /* left: 442px; */
    font-size: 22px;
    display: inline-block;
    width: 200px;
    height: 62px;
  }

  .the-only-constant {
    position: absolute;
    top: 25px;
    /* left: 243px; */
    font-size: 18px;
   
    display: inline-lock;
    width: 320px;
  }
  .and-the-bottom-container {
    position: absolute;
    top: 85px;
    /* left: 24px; */
    text-align: center;
    display: inline-block;
    width: 320px;
   font-size: 14px;
  }

  .statesecondary11,
  .statesecondary12,
  .statesecondary13 {
    position: absolute;
    top: 870px;
    border-radius: var(--br-981xl);
    border: 1px solid var(--color-darkkhaki);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-5xl);
    text-align: left;
    font-size: 6px;
    color: var(--color-darkkhaki);
    
  }
  .statesecondary11 {
 
    left: 30px;
    cursor: pointer;
  
  }
  .statesecondary12,
  .statesecondary13 {
    left: 140px;
    width: 70px;
    
  }
  .statesecondary13 {
    left: 270px;
    cursor: pointer;

  }


  .our-vision-mission{
    top: 940px;
    left: 70px;  
   justify-content: center;
   display: flex;
 
   /* text-align: left;
   width: 711px; */
   text-align: center;
   font-size: 18px;
 }
 .about-page-11-inner,
 .about-page-11-item {
   position: absolute;
   top: 980px;
   left: 5%;
   border-radius: var(--br-xl);
   background-color: var(--color-gray-700);
   border: 1px solid var(--color-burlywood);
   box-sizing: border-box;
   width: 90%;
   height: 200px;
 }
 
 .about-page-11-inner {
   top:1200px;
   height: 11%;
 }
 .mission,
.vision {
  position: absolute;
  top:1050px;
  left:30px;
  font-size: 18px;

}
.mission {
  position: absolute;
  top: 44%;
  left: 74%;
  font-size: 18PX;
}
.to-be-a {
  position: absolute;
  display: inline-block;
  
}
.to-be-a {
  position: absolute;
  display: inline-block;
   top: 1000px;
   left: 80px;
  text-align: right;
  width: 260px;
  font-size: 14px;
 
}
.to-champion-transformative {
  position: absolute;
  top: 1210px;
  left: 7%;
  text-align: left;
  display: inline-block;
  width:70%;
  font-size: 14px;
}
.values {
  position: absolute;
  top: 52%;
  left: 38%;
  font-size: 22px;

}
.our-values-are {
  position: absolute;
  top:53%;
  left: 7%;
  display: inline-block;
  width: 80%;
} 
.group-item {
  position: relative;
  top: 0;
  left: 0;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-700);
  border: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 326px;
  height: 199px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rectangle-parent5 {
  position: absolute;
  top: -60%;
  left: 7%;

 
  margin: auto;

  width: 70%;
  height: 20;
  font-size:12px;
  
 
}
.rectangle-parent6 {
  position: absolute; 
  top: -130px;
  left: -4%;
  width: 326px;
  height: 222px;
  

  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.rectangle-parent7 {
   position: absolute;
  top:90px;
  left: -4%; 
  width: 326px;
  height: 222px;
  display:flex;
  margin: 40px;

}
.rectangle-parent8 {
  position: absolute;
  top: 310px;
  left: -4%;
  width: 326px;
  height: 222px;
  margin: 40px;

}
.rectangle-parent9 {
  position: absolute;
  top:530px;
  left: -4%;
  width: 326px;
  height: 222px;
  margin: 40px;

}

.statesecondary14,
.statesecondary15,
.statesecondary16 {
  position: absolute;
  top: 2870px;
  border-radius: var(--br-981xl);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  text-align: left;
  font-size:10px;
  color: var(--color-darkkhaki);
}
.statesecondary14 {
  left: 3px;
  cursor: pointer;
}
.statesecondary15,
.statesecondary16 {
  left: 110px;
}
.statesecondary16 {
 left: 230px;
}
.read-less {
  position: absolute;
padding-top: 2700px;
  left: 85%; 
  color: var(--color-darkkhaki);
  /* align-items: left; */
  cursor: pointer;
}
.editbuttonmiddle{
  position:absolute;
  top: 32%;
  cursor: pointer;
  height: 20px;
left: 38%;
}

.editbuttonvalue{
  position:absolute;
  top: 57%;
  left: 32%;
  cursor: pointer;
  height: 20px;
  float: right;
}
.mainedit{
  position:absolute;
  top: 10%;
  cursor: pointer;
  height: 30px;
  float: right;
  left: 10%;
}
.mainsave{
  position:absolute;
  top: 10%;
  cursor: pointer;
  height: 30px;
  float: right;
  left: 10%;
}
.about-page-11-child{
  display: none;
}
  }
