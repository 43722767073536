.vector-icon6,
.vector-icon7 {
  position: absolute;
  height: 63.62%;
  width: 25.86%;
  top: 36.38%;
  right: 0;
  bottom: 0;
  left: 74.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon7 {
  height: 58.36%;
  width: 51.98%;
  top: 41.64%;
  right: 48.02%;
  left: 0;
}
.icon5 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.frame-child6 {
  position: absolute;
  top: 19px;
  left: 10px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 1180px;
  height: 683px;
}
.branding-and-strategic {
  position: absolute;
  top: 73px;
  left: 172px;
  display: inline-block;
  width: 556px;
}
.strategic-branding-cohesive {
  margin-bottom: 0;
}
.strategic-branding-cohesive-a {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.blank-line4 {
  margin: 0;
}
.brands-and-organisations-container,
.strategic-branding-cohesive-container,
.strengthen-your-brand-container {
  position: absolute;
  top: 300px;
  left: 89px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 482px;
  z-index: 5;
}
.brands-and-organisations-container,
.strengthen-your-brand-container {
  top: 292px;
  left: 659px;
  width: 474px;
  height: 65px;
}
.brands-and-organisations-container {
  top: 525px;
  height: 47px;
}
.benefits6 {
  top: 228px;
  left: 705px;
  width: 110px;
  height: 24px;
}
.benefits6,
.ideal-for3,
.offerings3 {
  position: absolute;
  display: inline-block;
}
.ideal-for3 {
  top: 461px;
  left: 721px;
  width: 110px;
  height: 24px;
}
.offerings3 {
  top: 235px;
  left: 89px;
  width: 130px;
}
.benefit-icon3 {
  position: absolute;
  top: 223.5px;
  left: 657.5px;
  width: 33px;
  height: 33px;
}
.frame-child7,
.frame-child8 {
  position: absolute;
  top: 27%;
  left: 53%;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 537px;
  height: 198px;
}
.frame-child8 {
  top: 60%;
}
.ideal-icon3 {
  position: absolute;
  top: 450px;
  left: 659px;
  width: 46px;
  height: 46px;
}
.branding-icon1,
.frame-div {
  position: absolute;
  top: 49.5px;
  left: 42px;
  width: 99.25px;
  height: 81px;
}
.frame-div {
  top: 5%;
  left: 10%;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
}
.frame-icon5 {
  position: relative;
  width: 20px;
  height: 20px;
}
.auto-layout-horizontal5,
.auto-layout-horizontal6,
.auto-layout-vertical2 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
}
.auto-layout-horizontal5 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.auto-layout-horizontal6,
.auto-layout-vertical2 {
  position: absolute;
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.auto-layout-vertical2 {
  top: calc(50% - 276px);
  right: 133px;
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal6 {
  top: 140px;
  left: 1171px;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.button4 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary4 {
  position: absolute;
  top: 85%;
  left: 72%;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.glossary3 {
  left: 1050px;
  width: 74px;
}
.about3,
.contact3,
.glossary3,
.home3,
.rent-a-skill3,
.services3 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.about3 {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.contact3,
.home3,
.rent-a-skill3,
.services3 {
  left: 606px;
  width: 53px;
}
.contact3,
.rent-a-skill3,
.services3 {
  left: 904px;
  width: 106px;
}
.contact3,
.services3 {
  left: 790px;
  width: 74px;
  cursor: pointer;
}
.contact3 {
  left: 1164px;
  width: 66px;
}
.our-services-5-child {
  position: absolute;
  top: 61.5px;
  left: 72.8%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
  cursor: pointer;
}
.our-services-5 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: 100% auto;
  width: 100%;
  height: 115vh;
  /* overflow: hidden; */
  object-fit: contain !important;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit5{
  margin-right:78%;
  margin-top:7%;
 
}
@media (max-width: 768px){
.our-services-5 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: cover;
  overflow: hidden;

  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit5{
  margin-right:78%;
   z-index:1;
  margin-top: 50px; 
}
.Save5{
  margin-right:40%;
  margin-top: 50px;
   z-index:1;
}
.rectangle-parent2 {
  position: absolute;
  top: 64px;
  left: 33px;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
  z-index: 1;
}
.frame-child12 {
  position: absolute;
  top: 19px;
  left:-15px;
 
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 300px;
  height: 660px;

} 
.branding-icon1 {
  top: 51px;
  left: 0px;
  width: 66px;
  height: 40px;
}
.branding-and-strategic {
  position: absolute;
  top: 60px;
  left:60px;
  display: inline-block;
  width: 200px;
  font-size: 14px;
}
.offerings3 {
  top: 120px;
  left: 0px;
  width: 130px;
}
.strategic-branding-cohesive-container
 {
  position: absolute;
  top: 150px;
  left:1px;
  font-size: 12PX;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 250px;
  z-index: 5;
}
.frame-child7,
.frame-child8 {
  position: absolute;
  top: 310px;
  left: 1px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 250px;
  height: 140px;
}
.frame-child8{
  top: 470px;
}
.brands-and-organisations-container,
.strengthen-your-brand-container {
  top: 365px;
  left: 2px;
  width: 250px;
  height: 65px;
  font-size: 12PX;
}
.brands-and-organisations-container {
  top: 525px;
  height: 47px;
}
.benefits6,
.ideal-for3,
.offerings3 {
  position: absolute;
  display: inline-block;
} 
.benefits6 {
  top: 335px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.benefit-icon3 {
  position: absolute;
  top: 330px;
  left: 2px;
  width: 20x;
  height: 25px;
}
.ideal-for3 {
  top: 480px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.ideal-icon3 {
  position: absolute;
  top: 475px;
  left: 2px;
  width: 30px;
  height: 30px;
}
.organizations-and-individuals-container {
  top: 510px;
  height: 68px;
}
.statesecondary4 {
  position: absolute;
  top: 690px;
  left: 95px;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.auto-layout-horizontal10,
.auto-layout-horizontal9,
.auto-layout-vertical4 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-layout-horizontal9 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.auto-layout-horizontal10,
.auto-layout-vertical4 {
  position: absolute;
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.auto-layout-vertical4 {
  top: 16.80%;
  left:75%;
 
   
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal10 {
  top: 95px;
  left:76%;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
  
}
.our-services-5-child {
  display: none;
}

}