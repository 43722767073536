.vector-icon10,
.vector-icon11 {
  position: absolute;
  height: 63.62%;
  width: 25.86%;
  top: 36.38%;
  right: 0;
  bottom: 0;
  left: 74.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon11 {
  height: 58.36%;
  width: 51.98%;
  top: 41.64%;
  right: 48.02%;
  left: 0;
}
.icon7 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.frame-child12 {
  position: absolute;
  top: 19px;
  left: 10px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 100%;
  height: 700px;

}
.training-and-learning {
  position: absolute;
  top: 71px;
  left: 170px;
  display: inline-block;
  width: 556px;
}
.blank-line11 {
  margin: 0;
}
.customisable-trainings-leader {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.customisable-trainings-leader-container {
  position: absolute;
  top: 300px;
  left: 89px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 482px;
  z-index: 5;
}
.enhance-organizational-and3 {
  margin-bottom: 0;
}
.enhance-organizational-and-container3,
.organizations-and-individuals-container3 {
  position: absolute;
  top: 292px;
  left: 659px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 474px;
  height: 65px;
  z-index: 5;
}
.organizations-and-individuals-container3 {
  top: 525px;
  height: 68px;
}
.benefits10 {
  top: 228px;
  left: 705px;
  width: 110px;
  height: 24px;
}
.benefits10,
.ideal-for5,
.offerings5 {
  position: absolute;
  display: inline-block;
}
.ideal-for5 {
  top: 461px;
  left: 721px;
  width: 110px;
  height: 24px;
}
.offerings5 {
  top: 235px;
  left: 89px;
  width: 130px;
}
.benefit-icon5 {
  position: absolute;
  top: 223.5px;
  left: 657.5px;
  width: 33px;
  height: 33px;
}
.frame-child13,
.frame-child14 {
  position: absolute;
  top: 201px;
  left: 627px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 537px;
  height: 198px;
}
.frame-child14 {
  top: 426px;
}
.ideal-icon5,
.training-icon1 {
  position: absolute;
  top: 450px;
  left: 659px;
  width: 46px;
  height: 46px;
}
.training-icon1 {
  top: 51px;
  left: 42px;
  width: 96px;
  height: 75px;
}
.rectangle-parent2 {
  position: absolute;
  top: 64px;
  left: 15%;
  width: 1200px;
  height: 704px;
  text-align: left;
  /* font-size: var(--heading-2-size); */
  z-index: 1;
}
.frame-icon9 {
  position: relative;
  width: 20px;
  height: 20px;
}
.auto-layout-horizontal10,
.auto-layout-horizontal9,
.auto-layout-vertical4 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
}
.auto-layout-horizontal9 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.auto-layout-horizontal10,
.auto-layout-vertical4 {
  position: absolute;
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.auto-layout-vertical4 {
  top: 16.80%;
  left:75%;
 
   
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal10 {
  top: 16.80%;
  left:78%;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.button6 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary6 {
  position: absolute;
  top: 85%;
  left: 72%;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.glossary5 {
  left: 1050px;
  width: 74px;
}
.about5,
.contact5,
.glossary5,
.home5,
.rent-a-skill5,
.services5 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.about5 {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.contact5,
.home5,
.rent-a-skill5,
.services5 {
  left: 606px;
  width: 53px;
}
.contact5,
.rent-a-skill5,
.services5 {
  left: 904px;
  width: 106px;
}
.contact5,
.services5 {
  left: 790px;
  width: 74px;
}
.contact5 {
  left: 1164px;
  width: 66px;
  cursor: pointer;
}
.our-services-3-child {
  position: absolute;
  top: 61.5px;
  left: 72.8%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
  cursor: pointer;
}
.our-services-3 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: 100% auto;
  width: 100%;
  height: 115vh;
  /* overflow: hidden; */
  object-fit: contain !important;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit3{
  margin-right:78%;
  margin-top:7%;

}
@media (max-width: 768px){
  .our-services-3 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: cover;
  overflow: hidden;
height: 750PX;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit3{
  margin-right:78%;
   z-index:1;
  margin-top: 15%; 
}
.Save3{
  margin-right:40%;
  margin-top:15%;
   z-index:1;
}
.rectangle-parent2 {
  position: absolute;
  top: 64px;
  left: 33px;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
  z-index: 1;
}
.frame-child12 {
  position: absolute;
  top: 19px;
  left:-15px;
 
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 300px;
  height:300px;

} 
.training-icon1 {
  top: 51px;
  left: 1px;
  width: 66px;
  height: 40px;
}
.training-and-learning {
  position: absolute;
  top: 60px;
  left:70px;
  display: inline-block;
  width: 556px;
  font-size: 14px;
}
.offerings5 {
  top: 120px;
  left: 0px;
  width: 130px;
}
  
.customisable-trainings-leader-container
{
  position: absolute;
  top: 150px;
  left:-10px;
  font-size:12px;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 300px;
}
.frame-child13,
.frame-child14 {
  position: absolute;
  top: 310px;
  left: 1px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 250px;
  height: 140px;
}
.frame-child14{
  top: 470px;
}


.benefits10,
.ideal-for5,
.offerings5 {
  position: absolute;
  display: inline-block;
} 
.benefits10 {
  top: 325px;
  left: 30px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.ideal-for5 {
  top: 480px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.ideal-icon5 {
  position: absolute;
  top: 475px;
  left: 3px;
  width: 30px;
  height: 30px;
} 
.benefit-icon5 {
  position: absolute;
  top: 320px;
  left: 6px;
  width: 20px;
  height: 25px;
}




.enhance-organizational-and-container3, .organizations-and-individuals-container3 {
  position: absolute;
  top: 350px;
  left: 1px;
  font-size: 12px;
  font-family: var(--font-inherit);
  display: inline-block;
  width:20%;
  height: 65px;
  z-index: 5;

}
.organizations-and-individuals-container3 {
  top: 525px;
  height: 68px;
}

.statesecondary6 {
  position: absolute;
  top: 690px;
  left: 95px;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.auto-layout-horizontal10,
.auto-layout-horizontal9,
.auto-layout-vertical4 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
}

.auto-layout-horizontal9 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.auto-layout-horizontal10,
.auto-layout-vertical4 {
  position: absolute;
  box-sizing: border-box;
 
  cursor: pointer;
}
.auto-layout-vertical4 {
  top: 16.80%;
  left:75%;
 
   
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal10 {
  top: 95px;
  left:76%;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
  width: 25px;
  height: 25px;
}
.our-services-3-child {
  display: none;
}
}
