.button29 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary17 {
  left: 31%;
  cursor: pointer;
}
.statesecondary17,
.statesecondary18,
.statesecondary19 {
  position: absolute;
  bottom: 7%;
  border-radius: var(--br-981xl);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkkhaki);
}
.statesecondary18 {
  left: 42%;
  cursor: pointer;
}
.statesecondary19 {
  left: 60%;
}
.icon24 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.about15,
.contact15,
.glossary20,
.home15,
.rent-a-skill20,
.services20 {
  position: absolute;
  top: 36px;
  left: 1090px;
  display: inline-block;
  width: 119px;
  height: 26px;
}
.about15,
.contact15,
.glossary20,
.home15,
.services20 {
  left: 1236px;
  width: 74px;
}
.about15,
.contact15,
.home15,
.services20 {
  left: 885px;
  width: 51px;
}
.contact15,
.home15,                           
.services20 {
  left: 792px;
  width: 53px;
  cursor: pointer;
}
.contact15,
.services20 {
  left: 976px;
  width: 74px;
}
.contact15 {
  left: 1350px;
  width: 66px;
}
.about-page-1-child {
  position: absolute;
  top: 61.5px;
  left: 67%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
}
.who-we-are1 {
  position: relative;
  top: 9px;
  left: 450px;
  font-size: var(--heading-1-size);
  display: inline-block;
  width: 315px;
  height: 62px;
  justify-content: center;
  display: flex;
}
.bringing-the-path {
  margin: 0;
}
.at-the-lighthouse-container,
.read-more {
  position: absolute;
  font-size: var(--body-text-size);
  text-align: center;
}
.at-the-lighthouse-container {
  top: 160px;
  left: 24px;
  display: inline-block;
  width: 1156px;
  height: 305px;
}
.read-more {
  top: 433px;
  left: 906px;
  color: var(--color-darkkhaki);
  cursor: pointer;
}
.lets-light-up6,
.the-only-constant1 {
  position: absolute;
  top: 113px;
  left: 243px;
  font-size: var(--subheading-size);
  font-weight: 300;
  display: inline-block;
  width: 721px;
}
.lets-light-up6 {
  top: 843px;
  left: 477px;
  width: 245px;
  height: 31px;
}
.statesecondary20,
.statesecondary21,
.statesecondary22 {
  position: absolute;
  top: 883px;
  left: 181px;
  border-radius: var(--br-981xl);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  color: var(--color-darkkhaki);
}
.statesecondary21,
.statesecondary22 {
  left: 442px;
}
.statesecondary22 {
  left: 853px;
}
.who-we-are-group {
  position: relative;
   top: 18%;
  width: 1200px;
  height: 489px;
  font-size: var(--font-size-sm);
  justify-content: center;
  display: inline-block;
}
.about-page-1 {

  position: relative;
  width: 100%;
  height: 832px;
  overflow: hidden;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
  background-image: url("../images/Aboutpage-1.png"); 
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  object-fit: contain !important;
  background-size: cover;
}



@media (max-width: 768px) {
  .who-we-are1{
    font-size: 22px !important;
    left: 10% !important;
    justify-content: left !important;
    top: -100px;
  }
  .who-we-are-group{
    /* position: none !important; */
  }
  .statesecondary17, .statesecondary18, .statesecondary19 {
    position: absolute;
    /* top: 85%; */
    bottom: 17%;
    border-radius: var(--br-981xl);
    border: 1px solid var(--color-darkkhaki);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px !important;
    text-align: left;
    font-size: 11px !important;
    color: var(--color-darkkhaki);
}
.statesecondary17{
  left: 10%;
  bottom: 20% !important;
}
.statesecondary18{
  left: 10%;
  bottom: 15% !important;
}
.statesecondary19{
  left: 10%;
   bottom: 10% !important;
}


.bringing-the-path {
    margin: 0;
    width: 50%;
}
user agent stylesheet
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.at-the-lighthouse-container{
  font-size: 14px;
}
.at-the-lighthouse-container{
      /* height: 0px !important; */
      width: 50%;
      top: 33px;
      left: 4%;
}
.about-page-1 {
  position: relative;
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  width: 100%;
  /* height: 832px; */
  height: 1000px !important;
  overflow: hidden;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
  background-image: url(../images/mob-about.png);
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  /* height: 100vh; */
  object-fit: contain !important;
  background-size: cover;
}
.who-we-are-group{
  /* width: 10px !important; */
}

.lets-light-up6, .the-only-constant1{
  position: absolute;
  /* top: 25% !important; */
  left: 61px;
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
  width: 277px;
}
.lets-light-up6{
  top: 0px !important;
  /* left: 6% !important; */
}
.lets-light-up6, .the-only-constant1{
  top: -45px;
}
.read-more {
  top: 115%;
  left: 20%;
  color: var(--color-darkkhaki);
  cursor: pointer;
}
.about-page-1-child{
  display: none;
}
}