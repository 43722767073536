.vector-icon2,
.vector-icon3 {
  position: absolute;
  height: 63.62%;
  width: 25.86%;
  top: 36.38%;
  right: 0;
  bottom: 0;
  left: 74.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon3 {
  height: 58.36%;
  width: 51.98%;
  top: 41.64%;
  right: 48.02%;
  left: 0;
}
.icon2 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
/* .rectangle-div {
  position: absolute;
  top: 19px;
  left: 10px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 1180px;
  height: 683px;
} */
.writing-and-editorial {
  position: absolute;
  top: 77px;
  left: 159px;
  display: inline-block;
  width: 556px;
}
.copywriting-excellence-articu {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.blank-line2 {
  margin: 0;
}
.copywriting-excellence-articu-container {
  position: absolute;
  top: 300px;
  left: 89px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 482px;
}
.enhance-the-quality {
  margin-bottom: 0;
}
.authors-corporations-and-container,
.enhance-the-quality-container {
  position: absolute;
  top: 292px;
  left: 659px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 474px;
  height: 65px;
  z-index: 5;
}
.authors-corporations-and-container {
  top: 525px;
  height: 62px;
}
.benefits2 {
  top: 228px;
  left: 705px;
  width: 110px;
  height: 24px;
}
.benefits2,
.ideal-for1,
.offerings1 {
  position: absolute;
  display: inline-block;
}
.ideal-for1 {
  top: 461px;
  left: 721px;
  width: 110px;
  height: 24px;
}
.offerings1 {
  top: 235px;
  left: 89px;
  width: 130px;
}
.benefit-icon1 {
  position: absolute;
  top: 223.5px;
  left: 657.5px;
  width: 33px;
  height: 33px;
}
.frame-child1,
.frame-child2 {
  position: absolute;
  top: 201px;
  left: 627px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 537px;
  height: 198px;
}
.frame-child2 {
  top: 426px;
}
.ideal-icon1 {
  position: absolute;
  top: 450px;
  left: 659px;
  width: 46px;
  height: 46px;
}
.editor-icon1,
.rectangle-group {
  position: absolute;
  top: 49.5px;
  left: 40.5px;
  width: 87.5px;
  height: 87.5px;
}
.rectangle-group {
  top: 7%;
  left: 8%;
  /* width: 80%;
  height: 83%; */
  text-align: left;
  font-size: va r(--heading-2-size);
}
.frame-icon1 {
  position: relative;
  width: 20px;
  height: 20px;
}
.auto-layout-horizontal1,
.auto-layout-horizontal2,
.auto-layout-vertical {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
}
.auto-layout-horizontal1 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.auto-layout-horizontal2,
.auto-layout-vertical {
  position: absolute;
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.auto-layout-vertical {
  top: calc(50% - 276px);
  right: 133px;
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal2 {
  top: 140px;
  left: 1171px;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
}
.button2 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary2 {
  position: absolute;
  top: 85%;
  left: 72%;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.glossary1 {
  left: 1050px;
  width: 74px;
}
.about1,
.contact1,
.glossary1,
.home1,
.rent-a-skill1,
.services1 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.about1 {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.contact1,
.home1,
.rent-a-skill1,
.services1 {
  left: 606px;
  width: 53px;
}
.contact1,
.rent-a-skill1,
.services1 {
  left: 904px;
  width: 106px;
}
.contact1,
.services1 {
  left: 790px;
  width: 74px;
  cursor: pointer;
}
.contact1 {
  left: 1164px;
  width: 66px;
}
.our-services-7-child {
  position: absolute;
  top: 61.5px;
  left: 72.8%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
  cursor: pointer;
}
.our-services-7 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: 100% auto;
  width: 100%;
  height: 115vh;
  /* overflow: hidden; */
  object-fit: contain !important;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit7{
  
  margin-right:78%;
 margin-top:7%;
 
}

@media (max-width: 768px){
  .our-services-7 {
    position: relative;
    background-image: url(../images/ourservices-1.png);
    /* background: linear-gradient(59.63deg, #282828, #353535); */
    background-size: cover;
    overflow: hidden;
  
    text-align: center;
    
    color: var(--color-whitesmoke);
    font-family: var(--body-text);
  }
  .rectangle-parent2 {
    position: absolute;
    top: 64px;
    left: 33px;
    width: 1200px;
    height: 704px;
    text-align: left;
    font-size: var(--heading-2-size);
    z-index: 1;
  }
  .edit7{
    margin-right:78%;
     z-index:1;
    margin-top: 50px; 
  }
  .Save7{
    margin-right:40%;
    margin-top: 50px;
     z-index:1;
  }
  .frame-child12 {
    position: absolute;
    top: 19px;
    left:-15px;
   
    border-radius: var(--br-xl);
    background-color: var(--color-gray-800);
    width: 300px;
    height: 660px;
  
  } 
  .editor-icon1 {
    top: 51px;
    left: 1px;
    width: 66px;
    height: 40px;
  }
  .writing-and-editorial {
    position: absolute;
    top: 60px;
    left:70px;
    display: inline-block;
    width: 150px;
    font-size: 14px;
  }
  .offerings1 {
    top: 120px;
    left: 0px;
    width: 130px;
  }
  .copywriting-excellence-articu-container {
    position: absolute;
    top: 150px;
    left: 1px;
    font-size:12PX;
    font-family: var(--font-inherit);
    display: inline-block;
    width: 250px;
  }
  .frame-child1,
  .frame-child2 {
    position: absolute;
    top: 310px;
    left: 1px;
    border-radius: var(--br-3xs);
    border: 1px solid var(--color-darkkhaki);
    box-sizing: border-box;
    width: 250px;
    height: 140px;
  }
  .frame-child2{
    top: 470px;
  }
  .brands-and-organisations-container,
  .strengthen-your-brand-container {
    top: 365px;
    left: 2px;
    width: 250px;
    height: 65px;
    font-size: 12PX;
  }
  .brands-and-organisations-container {
    top: 525px;
    height: 47px;
  }
  .benefits2,
  .ideal-for1,
  .offerings1 {
    position: absolute;
    display: inline-block;
  } 
  .benefits2 {
    top: 335px;
    left: 40px;
    width: 10px;
    height: 24px;
    font-size: 12px;
  }
  .benefit-icon1 {
    position: absolute;
    top: 330px;
    left: 2px;
    width: 20x;
    height: 25px;
  }
  .ideal-for1 {
    top: 480px;
    left: 40px;
    width: 10px;
    height: 24px;
    font-size: 12px;
  }
  .ideal-icon1 {
    position: absolute;
    top: 475px;
    left: 2px;
    width: 30px;
    height: 30px;
  }
  
  .statesecondary2 {
    position: absolute;
    top: 690px;
    left: 95px;
    border-radius: var(--br-981xl);
    background-color: var(--color-darkkhaki);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-5xl);
    cursor: pointer;
    text-align: left;
    font-size: var(--button-text-size);
  }
  .auto-layout-horizontal10,
  .auto-layout-horizontal9,
  .auto-layout-vertical4 {
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkkhaki);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .auto-layout-horizontal9 {
    background-color: var(--color-gray-200);
    flex-direction: row;
    padding: var(--padding-xl);
  }
  .auto-layout-horizontal10,
  .auto-layout-vertical4 {
    position: absolute;
    box-sizing: border-box;
    width: 43px;
    height: 43px;
    cursor: pointer;
  }
  .auto-layout-vertical4 {
    top: 16.80%;
    left:75%;
   
     
    overflow: hidden;
    flex-direction: column;
  }
  .auto-layout-horizontal10 {
    top: 95px;
    left:76%;
    background-color: var(--color-gray-200);
    flex-direction: row;
    padding: 10px;
    width: 25px;
  height: 25px;
  }
  .authors-corporations-and-container,
.enhance-the-quality-container {
  position: absolute;
  top: 370px;
  left: 3px;
  font-size: 12PX;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 250px;
 
  z-index: 5;
}
.authors-corporations-and-container {
  top: 525px;

}
.our-services-7-child {
  display: none;
}
  }
