
.icon8 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.frame-child15 {
  position: absolute;
  top: 19px;
  left: 10px;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 1180px;
  height: 683px;
}
.creative-and-expressive {
  position: absolute;
  top: 78px;
  left: 161px;
  display: inline-block;
  width: 556px;
}
.blank-line14 {
  margin: 0;
}
.innovative-creative-writing {
  margin-bottom: 0;
}
.innovative-creative-writing-s {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.brands-corporations-and-container,
.elevate-your-brand-container,
.innovative-creative-writing-container {
  position: absolute;
  top: 300px;
  left: 89px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 482px;
  z-index: 5;
}
.brands-corporations-and-container,
.elevate-your-brand-container {
  top: 292px;
  left: 659px;
  width: 474px;
  height: 65px;
}
.brands-corporations-and-container {
  top: 525px;
  height: 68px;
}
.benefits12 {
  top: 228px;
  left: 705px;
  width: 110px;
  height: 24px;
}
.benefits12,
.ideal-for6,
.offerings6 {
  position: absolute;
  display: inline-block;
}
.ideal-for6 {
  top: 461px;
  left: 721px;
  width: 110px;
  height: 24px;
}
.offerings6 {
  top: 235px;
  left: 89px;
  width: 130px;
}
.benefit-icon6 {
  position: absolute;
  top: 223.5px;
  left: 657.5px;
  width: 33px;
  height: 33px;
}
.frame-child16,
.frame-child17 {
  position: absolute;
  top: 201px;
  left: 627px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 537px;
  height: 198px;
}
.frame-child17 {
  top: 426px;
}
.ideal-icon6 {
  position: absolute;
  top: 450px;
  left: 659px;
  width: 46px;
  height: 46px;
}
.creative-services-icon1 {
  position: absolute;
  top: 48.5px;
  left: 39.5px;
  width: 92px;
  height: 92px;
}
.rectangle-parent3 {
  position: absolute;
  top: 10%;
  left: 13%;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
}
.frame-icon11 {
  position: relative;
  width: 20px;
  height: 20px;
}
.auto-layout-horizontal11,
.auto-layout-horizontal12,
.auto-layout-vertical5 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: 12%; */
  left: 85%;
  
}
.auto-layout-horizontal11 {
  top: 16%;
  left: 90%;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);

}
.auto-layout-horizontal12,
.auto-layout-vertical5 {
  position: absolute;
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  cursor: pointer;
}
.auto-layout-vertical5 {
  top: calc(50% - 276px);
  right: 133px;
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal12 {
  top: 16.80%;
  left: 89%;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
  
}
.button7 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary7 {
  position: absolute;
  top: 85%;
  left: 72%;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.glossary6 {
  left: 1050px;
  width: 74px;
}
.about6,
.contact6,
.glossary6,
.home6,
.rent-a-skill6,
.services6 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.about6 {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.contact6,
.home6,
.rent-a-skill6,
.services6 {
  left: 606px;
  width: 53px;
}
.contact6,
.rent-a-skill6,
.services6 {
  left: 904px;
  width: 106px;
}
.contact6,
.services6 {
  left: 790px;
  width: 74px;
}
.contact6 {
  left: 1164px;
  width: 66px;
  cursor: pointer;
}
.our-services-2-child {
  position: absolute;
  top: 61.5px;
  left: 72.8%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
  cursor: pointer;
}
.our-services-2 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: 100% auto;
  width: 100%;
  height: 115vh;
  /* overflow: hidden; */
  object-fit: contain !important;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit1{
  margin-right:78%;
 margin-top:7%;
 
}

@media (max-width: 768px){
  .our-services-2 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: cover;
  overflow: hidden;
height: 100vh;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit1{
  margin-right:78%;
   z-index:1;
  margin-top: 30px; 
}
.Save1{
  margin-right:40%;
  margin-top: 50px;
   z-index:1;
}
.rectangle-parent2 {
  position: absolute;
  top: 64px;
  left: 33px;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
  z-index: 1;
}
.frame-child12 {
  position: absolute;
  top: 19px;
  left:-15px;
 
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 300px;
  height: 660px;

} 
.creative-services-icon1 {
  top: 51px;
  left: 0px;
  width: 66px;
  height: 40px;
}
.creative-and-expressive {
  position: absolute;
  top: 60px;
  left:60px;
  display: inline-block;
  width: 200px;
  font-size: 14px;
}
.offerings6 {
  top: 120px;
  left: 0px;
  width: 130px;
}
.innovative-creative-writing-container {
  position: absolute;
  top: 150px;
  left: 1px;
  font-size:12px;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 250px;
  z-index: 5;
}
.frame-child16,
.frame-child17 {
  position: absolute;
  top: 310px;
  left: 1px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 250px;
  height: 140px;
}
.frame-child17{
  top: 470px;
}
.enhance-organizational-and-container,
.organizations-and-individuals-container {
  position: absolute;
  top: 350px;
  left: 2px;
  font-size: 14px;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 247px;
  height: 65px;
  z-index: 5;
}
.benefits12,
.ideal-for6,
.offerings6 {
  position: absolute;
  display: inline-block;
} 
.benefits12 {
  top: 325px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.ideal-for6 {
  top: 480px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.benefit-icon6 {
  position: absolute;
  top: 320px;
  left: 6px;
  width: 20px;
  height: 25px;
}
.ideal-icon6 {
  position: absolute;
  top: 475px;
  left: 3px;
  width: 30px;
  height: 30px;
}
.brands-corporations-and-container,
.elevate-your-brand-container {
  top: 350px;
  left: 1px;
  width: 250px;
  height: 65px;
  font-size: 12px;
}
.brands-corporations-and-container {
  top:530px;
  height: 68px;
}
.statesecondary7 {
  position: absolute;
  top: 690px;
  left: 95px;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.auto-layout-horizontal10,
.auto-layout-horizontal9,
.auto-layout-vertical4 {
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-darkkhaki);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
  width: 2px;
  height: 25px;
}

.auto-layout-horizontal9 {
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: var(--padding-xl);
 
}
.auto-layout-horizontal10,
.auto-layout-vertical4 {
  position: absolute;
  box-sizing: border-box;
 
  cursor: pointer;
}
.auto-layout-vertical4 {
  top: 11.5%;
  left:65%;
  width: 7%;
  height: 3%;
 
   
  overflow: hidden;
  flex-direction: column;
}
.auto-layout-horizontal10 {
  top: 11.5%;
  left:73%;
  background-color: var(--color-gray-200);
  flex-direction: row;
  padding: 2%;
  width: 7%;
  height: 3%;
  z-index: 7;
}
.our-services-2-child {
  display: none;
}
}