
.icon9 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.frame-child18 {
  position: absolute;
  top: 7%;
  
 left:1%;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 105%;
  height: 690px;
}
.personal-development-and {
  position: absolute;
  top: 11%;
  left: 10%;
  display: inline-block;
  width: 556px;
}
.blank-line17 {
  margin: 0;
}
.transformative-coaching-publi {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.positive-habit-building {
  margin-bottom: 0;
}
.individuals-seeking-personal-container,
.navigate-your-transformative-container,
.transformative-coaching-publi-container {
  position: absolute;
  top: 32%;
  left: 89px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 482px;
  z-index: 5;
}
.individuals-seeking-personal-container,
.navigate-your-transformative-container {
  top: 38%;
  left: 659px;
  width: 474px;
  height: 86px;
}
.individuals-seeking-personal-container {
  top:75%;
  height: 46px;
}
.benefits14 {
  top: 228px;
  left: 705px;
  width: 110px;
  height: 24px;
}
.benefits14,
.ideal-for7,
.offerings7 {
  position: absolute;
  display: inline-block;
}
.ideal-for7 {
  top: 461px;
  left: 721px;
  width: 110px;
  height: 24px;
}
.offerings7 {
  top: 28%;
  left: 89px;
  width: 130px;
}
.benefit-icon7,
.personality-dev-icon1 {
  position: absolute;
  top: 5%;
  left: 0%;
  width: 106px;
  height: 110px;
}
.benefit-icon7 {
  top: 223.5px;
  left: 657.5px;
  width: 33px;
  height: 33px;
}
.frame-child19,
.frame-child20 {
  position: absolute;
  top: 201px;
  left: 627px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 537px;
  height: 198px;
}
.frame-child20 {
  top: 426px;
}
.ideal-icon7 {
  position: absolute;
  top: 450px;
  left: 659px;
  width: 46px;
  height: 46px;
}

.button8 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary8 {
  position: absolute;
  top: 85%;
  left: 72%;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}
.glossary7 {
  left: 1050px;
  width: 74px;
}
.about7,
.contact7,
.glossary7,
.home7,
.rent-a-skill7,
.services7 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.about7 {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.contact7,
.home7,
.rent-a-skill7,
.services7 {
  left: 606px;
  width: 53px;
}
.contact7,
.rent-a-skill7,
.services7 {
  left: 904px;
  width: 106px;
}
.contact7,
.services7 {
  left: 790px;
  width: 74px;
  cursor: pointer;
}
.contact7 {
  left: 1164px;
  width: 66px;
}
.our-services-1-child {
  position: absolute;
  top: 61.5px;
  left:72.8%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
}
.frame-icon13 {
  position: relative;
  width: 20px;
  height: 20px;
}
.auto-layout-horizontal13 {
  position: absolute;
  top: 140px;
  left: 80%;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
  cursor: pointer;
  z-index: 7;
}
.tthe-only-constant-textfield{

  width: 10%;
left:50%;
}
.our-services-1 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  background-size: 100% auto;
  width: 100%;
  height: 115vh;
  object-fit: contain !important;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit1{
margin-right:78%;
 margin-top:7%;
 z-index:1;
}

@media (max-width: 768px){
  .our-services-1 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: cover;
  overflow: hidden;
height: 110vh;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit1{
  margin-right:78%;
   z-index:1;
  margin-top: 15%; 
}
.Save1{
  margin-right:40%;
  margin-top: 10%;
   z-index:1;
}

.rectangle-parent2 {
  position: absolute;
  top: 64px;
  left: 13%;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
  z-index: 1;
}
.frame-child12 {
  position: absolute;
  top: 19px;
  left:-15px;
 
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  width: 300px;
  height: 650px;

} 
.personality-dev-icon1  {
  top: 51px;
  left: 0px;
  width: 66px;
  height: 40px;
}
.personal-development-and {
  position: absolute;
  top: 60px;
  left:60px;
  display: inline-block;
  width: 200px;
  font-size: 14px;
}
.offerings7 {
  top: 120px;
  left: 0px;
  width: 130px;
}
.transformative-coaching-publi-container {
  position: absolute;
  top: 140px;
  left: 1px;
  font-size: 12px;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 250px;
  z-index: 5;
}
.frame-child19,
.frame-child20 {
  position: absolute;
  top: 365px;
  left: 1px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 250px;
  height: 140px;
}
.frame-child20{
  top: 520px;
}
.enhance-organizational-and-container,
.organizations-and-individuals-container {
  position: absolute;
  top: 350px;
  left: 2px;
  font-size: 14px;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 247px;
  height: 65px;
  z-index: 5;
}
.benefits14,
.ideal-for7,
.offerings7 {
  position: absolute;
  display: inline-block;
} 
.benefits14 {
  top: 385px;
  left: 35px;
  width: 10px;

  font-size: 12px;
}
.ideal-for7 {
  top: 530px;
  left: 40px;
  width: 10px;
  height: 24px;
  font-size: 12px;
}
.benefit-icon7 {
  position: absolute;
  top: 380px;
  left: 6px;
  width: 20px;
  height: 25px;
}
.ideal-icon7 {
  position: absolute;
  top: 525px;
  left: 3px;
  width: 30px;
  height: 30px;
}
.individuals-seeking-personal-container,
.navigate-your-transformative-container {
  top: 420px;
  left: 1px;
  width:250px;

  font-size:12px;
}
.individuals-seeking-personal-container {
  top:580px;
  height: 46px;
}
.statesecondary8 {
  position: absolute;
  top: 730px;
  left: 95px;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  text-align: left;
  font-size: var(--button-text-size);
}.auto-layout-horizontal13 {
  position: absolute;
  top: 10.5%;
  left:72%;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;

}
.our-services-1-child {
  display: none;
}
}