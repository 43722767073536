.icon23 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.company4 {
  font-size: var(--button-text-size);
  text-transform: uppercase;
  font-weight: 900;
}
.about-us4,
.company4 {
  position: relative;
}
.auto-layout-horizontal98,
.auto-layout-vertical109 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.auto-layout-vertical109 {
  flex-direction: column;
}
.auto-layout-vertical109,
.auto-layout-vertical111 {
  border-radius: var(--br-5xs);
  overflow: hidden;
}
.auto-layout-vertical108 {
  height: 286px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.services18 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.auto-layout-vertical115 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--body-text-size);
}
.auto-layout-vertical114 {
  font-size: var(--button-text-size);
}
.auto-layout-vertical114,
.auto-layout-vertical117 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
}
.auto-layout-horizontal97 {
  position: absolute;
  top: 745px;
  left: 20%;
  width: 1074px;
  height: 208px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-181xl);
  text-align: left;
}
.developed-by-coherent4,
.faqs4 {
  position: absolute;
  top: 651px;
  left: 49%;
  text-align: left;
}
.developed-by-coherent4 {
  top: 944px;
  left: 80%;
  font-size: var(--font-size-3xs);
  transform: rotate(-90deg);
  transform-origin: 0 0;
}
.vector-icon46,
.vector-icon47,
.vector-icon48 {
  position: absolute;
  height: 88.97%;
  width: 24.92%;
  top: 0;
  right: 0;
  bottom: 11.03%;
  left: 75.08%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon47,
.vector-icon48 {
  height: 91.9%;
  width: 32.96%;
  right: 67.04%;
  bottom: 8.1%;
  left: 0;
}
.vector-icon48 {
  height: 27.15%;
  width: 79.15%;
  top: 72.85%;
  right: 0;
  bottom: 0;
  left: 20.85%;
}
.lets-light-up5 {
  align-self: stretch;
  position: relative;
}
.select-preferred-mode4 {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 24px;
  display: inline-block;
  width: 511px;
  flex-shrink: 0;
}
.auto-layout-horizontal106,
.auto-layout-vertical127 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.auto-layout-horizontal106 {
  flex-direction: row;
  justify-content: center;
  padding: 0 var(--padding-101xl);
  font-size: var(--button-text-size);
  color: var(--color-gray-500);
}
.auto-layout-vertical127 {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: center;
}
.auto-layout-horizontal107,
.auto-layout-horizontal108,
.auto-layout-horizontal109 {
  position: absolute;
  top: 4px;
  left: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.auto-layout-horizontal108,
.auto-layout-horizontal109 {
  left: 198px;
}
.auto-layout-horizontal109 {
  left: 351px;
}
.group-child16,
.group-child17,
.group-child18 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  width: 29px;
  height: 29px;
  cursor: pointer;
}
.group-child17,
.group-child18 {
  left: 153px;
}
.group-child18 {
  left: 306px;
}
.auto-layout-horizontal-parent2 {
  position: relative;
  width: 383px;
  height: 29px;
  font-size: var(--body-text-size);
  color: var(--color-gray-600);
}
.your-name4 {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 20px;
  border: none;
  outline: none;
}
.auto-layout-horizontal111,
.auto-layout-horizontal112 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.auto-layout-horizontal112 {
  flex: 1;
  overflow: hidden;
}
.auto-layout-horizontal111 {
  align-self: stretch;
  border-radius: var(--br-11xl);
  border: 2px solid var(--color-lightsteelblue);
  padding: var(--padding-base);
}
.auto-layout-vertical128,
.auto-layout-vertical129 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.auto-layout-vertical129 {
  align-self: stretch;
}
.auto-layout-vertical128 {
  border-radius: var(--br-24xl);
  background-color: var(--color-gray-100);
  width: 320px;
  overflow: hidden;
  flex-shrink: 0;
}
.auto-layout-horizontal110,
.stateprimary11 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.stateprimary11 {
  border-radius: var(--br-981xl);
  background-color: var(--color-black);
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  color: var(--color-white);
  font-family: var(--body-text);
  cursor: pointer;
}
.auto-layout-horizontal110 {
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--button-text-size);
  font-family: var(--font-archivo);
  margin-top: 1%;
}
.auto-layout-vertical125,
.auto-layout-vertical126 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auto-layout-vertical126 {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-13xl);
}
.auto-layout-vertical125 {
  position: absolute;
  top: 245px;
  left: calc(50% - 640px);
  border-radius: var(--br-9xs);
  background: radial-gradient(50% 50%at 50% 50%, #be9e45, #d8c691);
  border: 1px solid var(--color-lightsteelblue);
  box-sizing: border-box;
  width: 1280px;
  height: 305px;
  justify-content: center;
  padding: var(--padding-61xl) var(--padding-13xl);
  text-align: left;
  font-size: var(--heading-1-size);
  color: var(--color-gray-400);
}
.contact-us5 {
  position: absolute;
  top: 566px;
  left: 48%;
  text-align: left;
}
.about14,
.glossary19,
.rent-a-skill19 {
  position: absolute;
  top: 36px;
  left: 904px;
  display: inline-block;
  width: 119px;
  height: 26px;
}
.about14,
.glossary19 {
  left: 1050px;
  width: 74px;
}
.about14 {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.home14 {
  left: 606px;
  width: 53px;
  cursor: pointer;
}
.contact14,
.home14,
.services19 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.services19 {
  left: 790px;
  width: 74px;
  cursor: pointer;
}
.contact14 {
  left: 1164px;
  width: 66px;
}
.contact-us-child {
  position: absolute;
  top: 61.5px;
  left: 92.5%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
}
.contact-us-item,
.vector-icon49,
.vector-icon50,
.vector-icon51 {
  position: absolute;
  height: 1.64%;
  width: 1.41%;
  top: 55.07%;
  right: 45.23%;
  bottom: 43.29%;
  left: 53.36%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.contact-us-item,
.vector-icon50,
.vector-icon51 {
  right: 47.89%;
  left: 50.7%;
}
.vector-icon50,
.vector-icon51 {
  right: 50.55%;
  left: 48.05%;
}
.vector-icon51 {
  height: 1.48%;
  width: 1.56%;
  top: 55.16%;
  right: 53.2%;
  bottom: 43.36%;
  left: 45.23%;
}
.contact-us4 {
  position: relative;
  background: linear-gradient(59.63deg, #282828, #353535);
  width: 100%;
  height: 1095px;
  overflow: hidden;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}

@media (max-width: 768px) {
  .lets-light-up5 {
    font-size: 18px !important;
  }
  .auto-layout-vertical125 {
    /* position: absolute;
    top: 245px;
    left: calc(50% - 640px);
    border-radius: var(--br-9xs);
    background: radial-gradient(50% 50%at 50% 50%, #be9e45, #d8c691);
    border: 1px solid var(--color-lightsteelblue);
    box-sizing: border-box;
    width: 594px;
    height: 266px;
    justify-content: center;
    padding: var(--padding-61xl) var(--padding-13xl);
    text-align: left;
    font-size: var(--heading-1-size);
    color: var(--color-gray-400); */
  }

  .auto-layout-horizontal110,
  .stateprimary11 {
    display: block !important;
    margin-top: 8px;
  }
  .auto-layout-horizontal111 {
    padding: 10px !important;
  }
  .stateprimary11 {
    padding: 8px !important;
    width: 107px !important;
  }
  .button18 {
    font-weight: 600 !important;
    text-align: center !important;
  }
  .auto-layout-horizontal97 {
    position: absolute;
    top: 745px;
    left: 103px;
    width: 1074px;
    height: 208px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-181xl);
    text-align: center !important;
    display: none !important;
  }
  .auto-layout-vertical108 {
    height: 146px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .contact-us5 {
    position: absolute !important;
    top: 565px !important;
    left: 155px !important;
    text-align: left !important;
    /* margin-bottom: 20px !important; */
  }

  .contact-us-item,
  .vector-icon49,
  .vector-icon50,
  .vector-icon51 {
    height: 30px !important;
    width: 30px !important;
    top: 55% !important;
    /* display: flex !important;
  align-items: center !important;
  justify-content: space-between !important; */
    gap: 10px !important;
    left: 63%;
  }
  .contact-icons {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    gap: 27px !important;
  }
  .developed-by-coherent4,
  .faqs4 {
    position: absolute !important;
    /* top: 570px !important; */
    left: 177px !important;
    text-align: left !important;
    margin-top: 10px;
    /* display: none; */
  }
  .vector-icon49 {
    left: 20%;
  }
  .vector-icon50 {
    left: 36%;
  }
  .vector-icon51 {
    left: 50%;
  }
  .vector-icon52 {
    left: 79%;
  }
  .developed-by-coherent4, .faqs4 {
    position: absolute !important;
    /* bottom: 250px !important; */
    left: 175px !important;
    text-align: left !important;
    /* top: 0px !important; */
  }
  .developed-by-coherent4 {
    top: 944px;
    left: 1250px;
    font-size: var(--font-size-3xs);
    transform: rotate(0deg);
    transform-origin: 0 0;
}
.contact-us-child {
  display: none;
}
}
