.icon25,
.the-lighthouse-logo-no-tagline {
  position: absolute;
  top: 95px;
  width: 195px;
  height: 194px;
  object-fit: cover;
  justify-content: center;
}
.about16,
.contact16,
.glossary21,
.home16,
.services21 {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.about16 {
  left: 67%;
  width: 51px;
  cursor: pointer;
}
.contact16,
.home16,
.services21 {
  left: 62%;
  width: 53px;
  cursor: pointer;
}
.contact16,
.services21 {
  left: 72%;
  width: 74px;
  cursor: pointer;
}
.contact16 {
  left: 92%;
  width: 66px;
}
.navbar {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
  color: #999999;
  cursor: pointer;
}

.navbar-mobile {
  position: static;
  top: auto;
  left: auto;
  width: auto;
  height: auto;
  display: block;
  text-align: center;
  margin: 0 auto;
  float: none;
}

.mobile-nav-button {
  display: none; 
}
.button-menu{
  display: none;
}

@media (max-width: 768px) {
  .button-menu{
    display: block;
  }
.navbarnew,
  .about16,
.contact16,
.glossary21,
.home16,
.services21 {
  display: none;

}
.about16 {
  display: none;

}
.contact16,
.home16,
.services21 {
  display: none;

}
.contact16,
.services21 {
  display: none;

}
.contact16 {
  display: none;
}

  .rent-a-skill21{
    display: none;
  }
  .glossary21{
    display: none;
  }
  .navbar {
    display: none;
  }

  .mobile-nav-button {
    display: block;
  }

  .navbar-mobile {
    display: block;
    cursor: pointer;
  }
}

.navbar-mobile {
  position: absolute;
  top: 53px;
  right: 0;
  border:1px solid #bf9f45;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  background-color: transparent;
  backdrop-filter: blur(20px);
  padding: 10px; 
  z-index: 1000; 
  text-align: left; 
  line-height: 40px;
}

.navbar-mobile > div {
  margin: 10px 0;
}

.navbar-mobile a {
  text-decoration: none;
  color: #333; 
}




.frame {
  top: 300px;
  align-items: center;
} 



.landing-page-child {
  position: absolute;
  top: 61.5px;
  left: 62%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 56px;
  height: 1px;
}
.frame-child21 {
  width: "100%";
  background-color: "red";
  border: "1px solid red";
}
.lighthouse {
  background: linear-gradient(
    217.81deg,
    #bf9f45,
    #be9e45 0.01%,
    #fff 49%,
    #d8c691
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.welcome-to-the-container {
  /* position: absolute;
   top: 0;
  left: 0;
  display: inline-block; 
   align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  font-size: var(--body-text-size); */
}
.frame {
  position: absolute;
  top: 300px;
  /* left: 470px; */
  width: 658px;
  height: 59px;
  align-items: center;
}
.bringing-the-path1,
.frame1 {
  position: absolute;
  height: 35px;
}
.bringing-the-path1 {
  /* top: 0;
  left: 40px;
  font-weight: 300;
  display: inline-block;
  width: 474px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame1 {
  top: 50%;
  /* left: 500px; */
  width: 514px;
  /* overflow: hidden; */
  font-size: var(--subheading-size);
}
.the-lighthouse- {
  margin: 0;
}
.the-lighthouse-container {
  position: absolute;
  top: 55%;
  /* left: 280px; */
  font-size: var(--body-text-size);
  text-align: center;
  display: inline-block;
  width: 962px;
  height: 173px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.button35 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.stateprimary12 {
  position: absolute;
  top: 90%;

  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  font-size: var(--button-text-size);
}
.rectangle-parent10 {
  /* position: absolute;
  top: 64px;
  left: 40px; */
  width: "100%";
  height: "100%";
  display: flex;
  justify-content: center;
  align-items: center;
  border: #382a04;
  height: 704px;
  text-align: center;
  font-size: var(--heading-1-size);
}
.landing-page {
  /* position: relative; */
  background-image: url("../images/LandingPage.png");
  width: 100vw;
   height: 100vh; 
   overflow: hidden; 
  text-align: center; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  object-fit: contain !important;
}
.savebutton {
  top: 1000px;
  cursor: pointer;
  height: 30px;
  float: right;
}
.editbutton {
  top: 1000px;
  cursor: pointer;
  height: 30px;
  float: right;
}

@media (max-width: 768px) {
  .rectangle-parent10 {
    font-size: 22px;
  }
  .bringing-the-path1 {
    font-size: 16px;
  }
   .the-lighthouse-logo-no-tagline {
    position: absolute;
    top: 95px;
    width: 195px;
    height: 194px;
    object-fit: cover;
    justify-content: center;
}
  .the-lighthouse-container {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    top: 59%;
  }
  .bringing-the-path1,
  .frame1 {
    top: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .stateprimary12 {
    /* bottom: 5% !important; */
    top: 93% !important;
  }
  .landing-page{
    background-image: url(../images/mob-home.png);
    background-size: cover;
    background-repeat: no-repeat;

    background-position:  center;
    height: 100vh!important;
    overflow: hidden;
  }
  .frame1{
    width: 100% !important;
  }
  .frame{
    width: 100% !important;
  }
  .contact-us-child {
  display: none;
}
}
