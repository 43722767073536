.vector-icon,
.vector-icon1 {
  position: absolute;
  height: 63.62%;
  width: 25.86%;
  top: 36.38%;
  right: 0;
  bottom: 0;
  left: 74.14%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vector-icon1 {
  height: 58.36%;
  width: 51.98%;
  top: 41.64%;
  right: 48.02%;
  left: 0;
}
.icon1 {
  position: absolute;
  top: 17px;
  left: 20px;
  width: 217px;
  height: 40px;
  object-fit: cover;
}
.frame-child{
   position: absolute;
  top: 64px;
  left: 15%;
  width: 1200px;
  height: 704px;
  text-align: left;
  font-size: var(--heading-2-size);
  z-index: 1;
  
}
.comprehensive-organisational-t {
  position: absolute;
  top: 76px;
  left: 165px;
  display: inline-block;
  width: 833px;
}
.holistic-individual-and {
  margin-bottom: 0;
 
}
.holistic-individual-and-team-e {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.blank-line {
  margin: 0;
}
.embark-on-a-container,
.holistic-individual-and-container,
.organisations-aspiring-to-container {
  position: absolute;
  top: 252px;
  left: 89px;
  font-size: var(--font-inherit);
  font-family: var(--font-inherit);
  display: inline-block;
  width: 482px;
  z-index: 5;
}
.embark-on-a-container,
.organisations-aspiring-to-container {
  top: 276px;
  left: 658px;
  width: 497px;
  height: 102px;
}
.organisations-aspiring-to-container {
  top: 490px;
  height: 81px;
}
.benefits {
  top: 228px;
  left: 705px;
  width: 110px;
  height: 24px;
}
.benefits,
.ideal-for,
.offerings {
  position: absolute;
  display: inline-block;
}
.ideal-for {
  top: 450px;
  left: 721px;
  width: 110px;
  height: 24px;
}
.offerings {
  top: 200px;
  left: 89px;
  width: 130px;
}
.benefit-icon {
  position: absolute;
  top: 223.5px;
  left: 657.5px;
  width: 33px;
  height: 33px;
}
.frame-inner,
.frame-item {
  position: absolute;
  top: 201px;
  left: 627px;
  border-radius: var(--br-3xs);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 537px;
  height: 198px;
}
.frame-inner {
  top: 426px;
}
.ideal-icon {
  position: absolute;
  top: 431px;
  left: 659px;
  width: 46px;
  height: 46px;
}
.org-transform-icon1 {
  position: absolute;
  top: 49.25px;
  left: 40.25px;
  width: 94.5px;
  height: 86.5px;
}
.button1 {
  position: relative;
  text-transform: uppercase;
  font-weight: 900;
}
.statesecondary1 {
  position: absolute;
  top: 92%;
  left: 79%;
  border-radius: var(--br-981xl);
  background-color: var(--color-darkkhaki);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-5xl);
  cursor: pointer;
  font-size: var(--button-text-size);
}
.rectangle-parent {
  position: absolute;
  top: 8%;
  left: 10%;
  width: 1200px;
  height: 704px;
  text-align: left;
  /* font-size: var(--heading-2-size); */
}
.frame-icon {
  position: relative;
  width: 20px;
  height: 20px;
}
.auto-layout-horizontal {
  position: absolute;
  top: 140px;
  left: 1171px;
  border-radius: var(--br-9xs);
  background-color: var(--color-gray-200);
  border: 1px solid var(--color-darkkhaki);
  box-sizing: border-box;
  width: 43px;
  height: 43px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
  cursor: pointer;
  z-index: 7;
}
.glossary {
  position: absolute;
  top: 36px;
  left: 1050px;
  display: inline-block;
  width: 74px;
  height: 26px;
}
.about {
  left: 699px;
  width: 51px;
  cursor: pointer;
}
.about,
.contact,
.home,
.rent-a-skill,
.services {
  position: absolute;
  top: 36px;
  display: inline-block;
  height: 26px;
}
.home {
  left: 606px;
  width: 53px;
  cursor: pointer;
}
.contact,
.rent-a-skill,
.services {
  left: 904px;
  width: 106px;
}
.contact,
.services {
  left: 790px;
  width: 74px;
  cursor: pointer;
}
.contact {
  left: 1164px;
  width: 66px;
}
.our-services-8-child {
  position: absolute;
  top: 61.5px;
  left:72.8%;
  border-top: 1px solid var(--color-burlywood);
  box-sizing: border-box;
  width: 58.01px;
  height: 1px;
}
.our-services-8 {
  position: relative;
  background-image: url(../images/ourservices-1.png);
  /* background: linear-gradient(59.63deg, #282828, #353535); */
  background-size: 100% auto;
  width: 100%;
  height: 130vh;
  /* overflow: hidden; */
  object-fit: contain !important;
  text-align: center;
  font-size: var(--body-text-size);
  color: var(--color-whitesmoke);
  font-family: var(--body-text);
}
.edit8{
  margin-right:78%;
 margin-top:7%;
 
}@media (max-width: 768px){
  .our-services-8 {
    position: relative;
    background-image: url(../images/ourservices-1.png);
    /* background: linear-gradient(59.63deg, #282828, #353535); */
    background-size: cover;
    overflow: hidden;
    height: 1000px;
    text-align: center;
    
    color: var(--color-whitesmoke);
    font-family: var(--body-text);
  }
  .edit8{
    margin-right:78%;
     z-index:1;
    margin-top: 50px; 
  }
  .Save8{
    margin-right:40%;
    margin-top: 50px;
  
  }
  .rectangle-parent2 {
    position: absolute;
    top: 64px;
    left: 60%;
    width: 1200px;
    height: 900px;
    text-align: left;
    font-size: var(--heading-2-size);
    height: 1000px;
    z-index: 1;
  }
  .frame-child{
    position: absolute;
    top: 19px;
    left:-15px;
   
    border-radius: var(--br-xl);
    background-color: var(--color-gray-800);
    width: 300px;
    height: 900px;
  
  } 
  .org-transform-icon1  {
    top: 51px;
    left: 1px;
    width: 66px;
    height: 40px;
  }
  .comprehensive-organisational-t  {
    position: absolute;
    top: 55px;
    left:70px;
    display: inline-block;
    width: 200px;
    font-size: 14px;
  }
  .offerings {
    top: 120px;
    left: 0px;
    width: 130px;
  }
  .holistic-individual-and-container
   {
    position: absolute;
    top: 150px;
    left: 1px;
    font-size: 12px;
    font-family: var(--font-inherit);
    display: inline-block;
    width: 250px;
    z-index: 5;
    
  }
  /* .copywriting-excellence-articu-container {
    position: absolute;
    top: 150px;
    left: 1px;
    font-size:12PX;
    font-family: var(--font-inherit);
    display: inline-block;
    width: 250px;
  } */
  .frame-inner,
.frame-item  {
    position: absolute;
    top: 500px;
    left: 1px;
    border-radius: var(--br-3xs);
    border: 1px solid var(--color-darkkhaki);
    box-sizing: border-box;
    width: 250px;
    height: 140px;
  }
  .frame-item{
    top: 680px;
    height: 160px;
  }
  /* .brands-and-organisations-container,
  .strengthen-your-brand-container {
    top: 365px;
    left: 2px;
    width: 250px;
    height: 65px;
    font-size: 12PX;
  }
  .brands-and-organisations-container {
    top: 525px;
    height: 47px;
  } */
  .benefits,
  .ideal-for,
  .offerings {
    position: absolute;
    display: inline-block;
  } 
  .benefits {
    top: 515px;
    left: 40px;
    width: 10px;
    height: 24px;
    font-size: 12px;
  }
  .benefit-icon {
    position: absolute;
    top: 510px;
    left: 2px;
    width: 20x;
    height: 25px;
  }
  .ideal-for {
    top: 700px;
    left: 40px;
    width: 10px;
    height: 24px;
    font-size: 12px;
  }
  .ideal-icon {
    position: absolute;
    top: 690px;
    left: 2px;
    width: 30px;
    height: 30px;
  }
  
  .statesecondary1 {
    position: absolute;
    top: 850px;
    left: 55px;
    border-radius: var(--br-981xl);
    background-color: var(--color-darkkhaki);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-xs) var(--padding-5xl);
    cursor: pointer;
    text-align: left;
    font-size: var(--button-text-size);
  }
  .auto-layout-horizontal10,
  .auto-layout-horizontal9,
  .auto-layout-vertical4 {
    border-radius: var(--br-9xs);
    border: 1px solid var(--color-darkkhaki);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .auto-layout-horizontal9 {
    background-color: var(--color-gray-200);
    flex-direction: row;
    padding: var(--padding-xl);
  }
  .auto-layout-horizontal10,
  .auto-layout-vertical4 {
    position: absolute;
    box-sizing: border-box;
    width: 43px;
    height: 43px;
    cursor: pointer;
  }
  .auto-layout-vertical4 {
    top: 16.80%;
    left:75%;
   
     
    overflow: hidden;
    flex-direction: column;
  }
  .auto-layout-horizontal {
    top: 92px;
    left:72%;
    background-color: var(--color-gray-200);
    flex-direction: row;
    padding:10px;
    width: 25px;
    height: 25px;
  }
  
.embark-on-a-container,
.organisations-aspiring-to-container {
  top: 540px;
  left: 3px;
  width: 250px;
 
  font-size: 12px;
}
.organisations-aspiring-to-container {
  top: 730px;
  height: 81px;
}
.our-services-8-child {
  display: none;
}
  }